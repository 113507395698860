import "./style.scss";
import { useTranslation } from "react-i18next";
import { Button, Images } from "../../components";
import { useNavigate } from "react-router-dom";

export default function NotFound() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="not-found">
      <div className="content">
        <h1>404</h1>
        <p>{t("notFound")}</p>
      </div>
      <Button
        variant={"transparent"}
        beforeIcon={<Images.BackArrow color={"#ffffff"} />}
        title={t("home")}
        onClick={() => {
          navigate("/");
        }}
      />
    </div>
  );
}
