import { Images } from "../../../../components";
import "./style.scss";

export default function AddToCalendar({ title, content, startDate, endDate }) {
  const googleCalendarUrl = `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(
    title
  )}&dates=${startDate}/${endDate}&details=${encodeURIComponent(content)}`;

  const generateICS = () => {
    const icsContent =
      `BEGIN:VCALENDAR\r\n` +
      `VERSION:2.0\r\n` +
      `PRODID:-//Ваша Організація//Ваш Продукт//EN\r\n` +
      `BEGIN:VEVENT\r\n` +
      `UID:${Date.now()}@yourdomain.com\r\n` +
      `SUMMARY:${title}\r\n` +
      `DTSTAMP:${
        new Date().toISOString().replace(/[-:]/g, "").split(".")[0] + "Z"
      }\r\n` +
      `DTSTART:${startDate}\r\n` +
      `DTEND:${endDate}\r\n` +
      `DESCRIPTION:${content}\r\n` +
      `END:VEVENT\r\n` +
      `END:VCALENDAR\r\n`;

    const blob = new Blob([icsContent], {
      type: "text/calendar;charset=utf-8",
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${title}.ics`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  return (
    <div className="add-to-calendar dropdown-center">
      <button
        className="calendar-button"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <Images.Calendar />
      </button>
      <ul className="dropdown-menu">
        <li>
          <a className="dropdown-item" onClick={generateICS}>
            <Images.Apple />
          </a>
        </li>
        <li
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          data-bs-custom-class="custom-tooltip"
          data-bs-title="This top tooltip is themed via CSS variables."
        >
          <a
            className="dropdown-item"
            href={googleCalendarUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Images.Google />
          </a>
        </li>
      </ul>
    </div>
  );
}
