// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about {
  padding: 2rem 0;
  background: linear-gradient(90deg, #133418, #1c1b1a);
}
.about .info-card {
  margin-bottom: 1.5rem;
}
.about .info-card h4 {
  text-transform: uppercase;
}
.about .info-card .wrapper {
  color: black;
  background-color: #f5f6f6;
  height: 100%;
  padding: 2rem;
  border-radius: 1rem;
  border: 2px solid #1c1b1a;
}`, "",{"version":3,"sources":["webpack://./src/components/About/style.scss","webpack://./src/styles/_variables.scss"],"names":[],"mappings":"AAGA;EACE,eAAA;EACA,oDCEoB;ADJtB;AAGE;EACE,qBAAA;AADJ;AAEI;EACE,yBAAA;AAAN;AAEI;EACE,YAAA;EACA,yBCVO;EDWP,YAAA;EACA,aAAA;EACA,mBAAA;EACA,yBAAA;AAAN","sourcesContent":["@import \"../../styles/variables\";\n@import \"../../styles/mixins\";\n\n.about {\n  padding: 2rem 0;\n  background: $green-gray-gradient;\n  .info-card {\n    margin-bottom: 1.5rem;\n    h4 {\n      text-transform: uppercase;\n    }\n    .wrapper {\n      color: black;\n      background-color: $light-gray;\n      height: 100%;\n      padding: 2rem;\n      border-radius: 1rem;\n      border: 2px solid $dark-gray;\n    }\n  }\n}\n","//colors\n$main-color: #113418;\n$dark-gray: #1c1b1a;\n$light-gray: #f5f6f6;\n\n//gradients\n$gray-green-gradient: linear-gradient(90deg, $dark-gray, $main-color);\n$green-gray-gradient: linear-gradient(90deg, #133418, #1c1b1a);\n\n//font-weight:\n\n$bold: 700;\n$semibold: 500;\n$medium: 300;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
