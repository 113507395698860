import React from "react";
import "./style.scss";
import AddToCalendar from "./AddToCalendar/AddToCalendar";
import { useTranslation } from "react-i18next";

export default function Accordion({
  id,
  title,
  time,
  content,
  empty,
  startDate,
  endDate,
  cal,
}) {
  const { t } = useTranslation();
  return (
    <div className="accordion" id={`agendaAccordion${id}`}>
      <div className="accordion-item">
        <h2 className="accordion-header" id={`heading${id}`}>
          <button
            className={`accordion-button ${empty && `empty`}`}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={`#collapse${id}`}
            aria-expanded="true"
            aria-controls={`collapse${id}`}
          >
            <div className="event">
              <p className="time">{time}</p>
              <p className="topic">{title}</p>
            </div>
          </button>
        </h2>
        {!empty && (
          <div
            id={`collapse${id}`}
            className="accordion-collapse collapse"
            aria-labelledby={`heading${id}`}
          >
            <div className="accordion-body">{content}</div>
            <AddToCalendar
              title={title}
              content={t("headText")}
              startDate={startDate}
              endDate={endDate}
            />
          </div>
        )}
      </div>
    </div>
  );
}
