import logoWhiteEn from "../../assets/logos/accuWhiteEng.png";
import logoWhiteUa from "../../assets/logos/accuWhiteUa.png";
import logoBlueEn from "../../assets/logos/accuBlueEn.png";
import logoBlueUa from "../../assets/logos/accuBlueUa.png";
import { useTranslation } from "react-i18next";

export default function Accu({ className = "partner-logo ", color = "white" }) {
  const { i18n, t } = useTranslation();
  return (
    <a href="https://accu.in.ua" target="_blank" rel="noopener noreferrer">
      <img
        src={
          color === "white"
            ? i18n.language === "en"
              ? logoWhiteEn
              : logoWhiteUa
            : i18n.language === "en"
            ? logoBlueEn
            : logoBlueUa
        }
        alt={t("accu")}
        className={className}
      />
    </a>
  );
}
