import logoEN from "../../assets/logos/accuPartners/Cabinet_of_Ministers.png";
import logoUa from "../../assets/logos/accuPartners/ministry_office.png";
import { useTranslation } from "react-i18next";

export default function FedMin({ className = "partner-logo" }) {
  const { i18n, t } = useTranslation("translation", {
    keyPrefix: "partners.alts",
  });
  return (
    <a href="https://www.kmu.gov.ua/" target="_blank" rel="noopener noreferrer">
      <img
        src={i18n.language === "en" ? logoEN : logoUa}
        alt={t("cabMin")}
        className={className}
      />
    </a>
  );
}
