import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { Button, Images, Partners } from "../../components";
import FormIsSent from "./formIsSent/FormIsSent";
import "./style.scss";

export default function Registration() {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    email: "",
  });
  const [errors, setErrors] = useState({});

  const [isDataSent, setIsDataSent] = useState(false);

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const validateStep = () => {
    let newErrors = {};

    if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email))
      newErrors.email = t("errorInvalidEmail");

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateStep()) return;
    const backendUrl = `${process.env.REACT_APP_BACKEND_URL}/api/cancel/`;
    try {
      await axios.post(backendUrl, formData);
      setIsDataSent(true);
    } catch (error) {
      console.error("There was an error!", error);
    }
  };

  return (
    <>
      <div className="cancel-registration registration" id="register">
        <div className="container">
          <Button
            variant={"light"}
            beforeIcon={<Images.BackArrow color="#000000" />}
            title={t("home")}
            onClick={() => {
              navigate("/");
            }}
          />
          <div className="hero">
            {!isDataSent && (
              <div className="cancel-registration-title">
                <h2>{t("cancel_registration")}</h2>
                <h5>{t("enter_email")}</h5>
              </div>
            )}
            <div className="registration-form">
              {!isDataSent ? (
                <form onSubmit={handleSubmit}>
                  <>
                    <input
                      type="email"
                      className={`form-control ${errors.email && "error"}`}
                      id="email"
                      name="email"
                      placeholder={t("registerPlaceholderEmail")}
                      required
                      value={formData.email}
                      onChange={handleChange}
                    />
                    {errors.email && (
                      <div className="error-feedback">{errors.email}</div>
                    )}
                  </>
                  <div className="d-grid">
                    <Button
                      type="submit"
                      title={t("cancel_registration_title")}
                      variant="light"
                      className="submit"
                    />
                  </div>
                </form>
              ) : (
                <FormIsSent />
              )}
            </div>
          </div>
        </div>
      </div>
      <Partners />
    </>
  );
}
