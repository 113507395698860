import { useEffect, useRef } from "react";

export default function Textarea({ id, name, placeholder, value, onChange }) {
  const textareaRef = useRef(null);

  const adjustHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  useEffect(() => {
    adjustHeight();
  }, [value]);

  return (
    <textarea
      className="form-control"
      id={id}
      name={name}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      ref={textareaRef}
    />
  );
}
