// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.language-switcher {
  position: absolute;
  right: 1rem;
  top: 1rem;
  font-weight: 600;
  color: white;
  background-color: transparent;
  border: none;
  border-radius: 10%;
  padding: 10px 10px 6px 10px;
  z-index: 1000;
}`, "",{"version":3,"sources":["webpack://./src/components/languageSwitcher/style.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,WAAA;EACA,SAAA;EACA,gBAAA;EACA,YAAA;EACA,6BAAA;EACA,YAAA;EACA,kBAAA;EACA,2BAAA;EACA,aAAA;AADF","sourcesContent":["@import \"../../styles/variables\";\n\n.language-switcher {\n  position: absolute;\n  right: 1rem;\n  top: 1rem;\n  font-weight: 600;\n  color: white;\n  background-color: transparent;\n  border: none;\n  border-radius: 10%;\n  padding: 10px 10px 6px 10px;\n  z-index: 1000;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
