import { useTranslation } from "react-i18next";
import "./style.scss";

function LanguageSwitcher() {
  const { i18n } = useTranslation();

  const toggleLanguage = () => {
    const currentLanguage = i18n.language;
    const newLanguage = currentLanguage === "en" ? "uk" : "en";
    i18n.changeLanguage(newLanguage);
    localStorage.setItem("language", newLanguage);
  };

  return (
    <button className="language-switcher" onClick={toggleLanguage}>
      {i18n.language === "en" ? "UK" : "EN"}
    </button>
  );
}

export default LanguageSwitcher;
