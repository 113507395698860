import "./style.scss";

export default function Button({
  className,
  title,
  onClick,
  variant,
  beforeIcon = null,
  afterIcon = null,
  type = "button",
  disabled,
}) {
  return (
    <button
      disabled={disabled}
      type={type}
      onClick={onClick}
      className={"button " + className + " " + variant}
    >
      {beforeIcon}
      {title}
      {afterIcon}
    </button>
  );
}
