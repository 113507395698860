// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.location {
  padding: 2rem 0;
  background: linear-gradient(90deg, #133418, #1c1b1a);
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/Location/style.scss","webpack://./src/styles/_variables.scss"],"names":[],"mappings":"AAEA;EACE,eAAA;EACA,oDCGoB;EDFpB,YAAA;AADF","sourcesContent":["@import \"../../styles/variables\";\n\n.location {\n  padding: 2rem 0;\n  background: $green-gray-gradient;\n  color: white;\n}\n","//colors\n$main-color: #113418;\n$dark-gray: #1c1b1a;\n$light-gray: #f5f6f6;\n\n//gradients\n$gray-green-gradient: linear-gradient(90deg, $dark-gray, $main-color);\n$green-gray-gradient: linear-gradient(90deg, #133418, #1c1b1a);\n\n//font-weight:\n\n$bold: 700;\n$semibold: 500;\n$medium: 300;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
