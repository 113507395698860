// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/graphicelements/7vm.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.place {
  min-height: 50vh;
  position: relative;
  background: linear-gradient(90deg, #133418, #1c1b1a);
}
.place .background {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center bottom;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.place .background .container {
  padding: 0 0 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
}
.place .background .container .slogan {
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/Place/style.scss","webpack://./src/styles/_variables.scss"],"names":[],"mappings":"AAEA;EACE,gBAAA;EACA,kBAAA;EACA,oDCEoB;ADHtB;AAEE;EACE,yDAAA;EACA,wBAAA;EACA,4BAAA;EACA,kCAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;AAAJ;AACI;EACE,mBAAA;EACA,aAAA;EACA,sBAAA;EACA,6BAAA;EAEA,YAAA;AAAN;AACM;EACE,kBAAA;AACR","sourcesContent":["@import \"../../styles/variables\";\n\n.place {\n  min-height: 50vh;\n  position: relative;\n  background: $green-gray-gradient;\n  .background {\n    background-image: url(\"../../assets/graphicelements/7vm.png\");\n    background-size: contain;\n    background-repeat: no-repeat;\n    background-position: center bottom;\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    .container {\n      padding: 0 0 2rem 0;\n      display: flex;\n      flex-direction: column;\n      justify-content: space-around;\n\n      height: 100%;\n      .slogan {\n        text-align: center;\n      }\n    }\n  }\n}\n","//colors\n$main-color: #113418;\n$dark-gray: #1c1b1a;\n$light-gray: #f5f6f6;\n\n//gradients\n$gray-green-gradient: linear-gradient(90deg, $dark-gray, $main-color);\n$green-gray-gradient: linear-gradient(90deg, #133418, #1c1b1a);\n\n//font-weight:\n\n$bold: 700;\n$semibold: 500;\n$medium: 300;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
