import logo from "../../assets/logos/accuPartners/giz.png";

export default function GIZ({ className = "partner-logo" }) {
  return (
    <a href="https://www.giz.de" target="_blank" rel="noopener noreferrer">
      <img
        src={logo}
        alt="Німецьке товариство міжнародного співробітництва"
        className={className}
      />
    </a>
  );
}
