// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title {
  padding: 2rem 0;
  color: black;
}
.title h4 {
  text-align: center;
}
@media (min-width: 768px) {
  .title {
    padding: 2rem 20%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Title/style.scss","webpack://./src/styles/_mixins.scss"],"names":[],"mappings":"AAEA;EACE,eAAA;EACA,YAAA;AADF;AAEE;EACE,kBAAA;AAAJ;ACMI;EDVJ;IAOI,iBAAA;EACF;AACF","sourcesContent":["@import \"../../styles/mixins\";\n\n.title {\n  padding: 2rem 0;\n  color: black;\n  h4 {\n    text-align: center;\n  }\n  @include respond-to(md) {\n    padding: 2rem 20%;\n  }\n}\n","$breakpoints: (\n  xs: 0,\n  sm: 576px,\n  md: 768px,\n  lg: 992px,\n  xl: 1200px,\n  xxl: 1400px,\n);\n\n// Mixin for each breakpoint\n@mixin respond-to($breakpoint) {\n  @if map-has-key($breakpoints, $breakpoint) {\n    @media (min-width: map-get($breakpoints, $breakpoint)) {\n      @content;\n    }\n  } @else {\n    @warn \"Invalid breakpoint: #{$breakpoint}.\";\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
