import React from "react";
import { useTranslation } from "react-i18next";
import { Images, Button } from "../../components";
import { useNavigate } from "react-router-dom";
import "./style.scss";
import LanguageSwitcher from "../../components/languageSwitcher/LanguageSwitcher";
import Accordion from "./Accordion/Accordion";

export default function Agenda() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="agenda">
      <LanguageSwitcher />
      <div className="container">
        <Button
          variant={"transparent"}
          beforeIcon={<Images.BackArrow color={"#ffffff"} />}
          title={t("home")}
          onClick={() => {
            navigate("/");
          }}
        />
        <h2>{t("agendaMainTitle")}</h2>
        <h4>{t("eventDate1")}</h4>
        <div className="agenda-card">
          <Accordion
            id={"One"}
            title={t("arrival")}
            time={"12:00-18:00"}
            empty
          />
          <Accordion
            id={"Two"}
            title={t("eveningReception")}
            time={"18:00-20:00"}
            startDate={"20241017T150000Z"}
            endDate={"20241017T170000Z"}
          />
        </div>
        <h4>{t("eventDate2")}</h4>
        <div className="agenda-card">
          <Accordion
            id={"Three"}
            time={"09:30-11:00"}
            title={t("transfer")}
            empty
          />
          <Accordion
            id={"Four"}
            time={"11:00-11:30"}
            title={t("registration")}
            empty
          />
          <Accordion
            id={"Thirdteen"}
            time={"11:30-12:00"}
            title={t("openingEvent")}
            content={
              <div>
                <p>
                  {t("hostTitle")}:
                  <br /> <strong>{t("AndriyKulykov")}</strong>,
                  <br /> {t("journalist")}
                </p>
              </div>
            }
            startDate={"20241018T083000Z"}
            endDate={"20241018T090000Z"}
          />
          <Accordion
            id={"Five"}
            title={t("welcomeSpeech")}
            startDate={"20241018T090000Z"}
            endDate={"20241018T100000Z"}
            content={
              <div>
                {/* <p>
                  <strong>Денис Шмигаль, </strong> <br />
                  Прем'єр-міністр України;
                </p> */}
                <p>
                  <strong>{t("MaksymKozytskyy")}</strong>, <br />
                  {t("HeadOfLviv")}
                </p>
                <p>
                  <strong>{t("tim")}</strong>,<br />
                  {t("timTitle")}
                  <br />
                </p>
              </div>
            }
          />
          <Accordion
            id={"Six"}
            time={"12:00-13:00"}
            title={t("greenTransformation")}
            startDate={"20241018T090000Z"}
            endDate={"20241018T100000Z"}
            content={
              <div>
                <p>{t("participants")}:</p>
                <p>
                  <strong>{t("riabykin")}</strong>,<br />
                  {t("riabykinTitle")};
                </p>
                <p>
                  <strong>{t("victoriaKyreieva")}</strong>,<br />
                  {t("victoriaKyreievaTitle")}
                  <br />
                </p>
                <p>
                  <p>
                    <strong>{t("hermanHalushchenko")}</strong>,<br />
                    {t("hermanHalushchenkoTitle")};
                  </p>
                  <strong>{t("som")}</strong>,<br />
                  {t("somTitle")}
                  <br />
                </p>
                <p>
                  <strong>{t("woelert")}</strong>,<br />
                  {t("woelertTitle")}
                  <br />
                  <em>({t("online")});</em>
                </p>
                <p>
                  {t("moder")} <br />
                  <strong>{t("AndriyKulykov")}</strong>,<br /> {t("journalist")}
                  ;
                </p>
              </div>
            }
          />
          <Accordion
            id={"Seven"}
            title={t("memorandumSigning")}
            time={"13:00-13:30"}
            empty
          />
          <Accordion id={"twenty"} title={t("memorandum2Signing")} empty />

          <Accordion
            id={"lunch"}
            time={"13:30-14:30"}
            title={t("lunchBreak")}
            empty
          />
          <Accordion
            id={"Eight"}
            title={t("independent")}
            time={"14:30-15:30"}
            startDate={"20241018T113000Z"}
            endDate={"20241018T123000Z"}
            content={
              <div>
                <p>{t("participants")}:</p>
                <p>
                  <strong>{t("andriyHerus")}</strong>,<br />
                  {t("andriyHerusTitle")};
                </p>

                <p>
                  <strong>{t("vitaliyKindratov")}</strong>,<br />
                  {t("vitaliyKindratovTitle")};
                </p>
                <p>
                  <strong>{t("miriamFaulwetter")}</strong>,<br />
                  {t("miriamFaulwetterTitle")}
                  <br />
                </p>
                <p>
                  <strong>{t("julieConen")}</strong>,<br />
                  {t("julieConenTitle")}
                  <br />
                  <em>({t("online")});</em>
                </p>
                <p>
                  {t("moder")} <br />
                  <strong>{t("andriyTabinsky")}</strong>,<br />
                  {t("andriyTabinskyTitle")};
                </p>
              </div>
            }
          />
          <Accordion
            id={"Nine"}
            title={t("memorandum3signing")}
            time={"15:30-15:40"}
            empty
          />
        </div>

        <h5>{t("coffeeBreak")}, 15:40-16:00</h5>
        <div className="agenda-card">
          <Accordion
            id={"Twelwe"}
            title={t("strongerTogether")}
            time={"16:00-17:00"}
            startDate={"20241018T130000Z"}
            endDate={"20241018T140000Z"}
            content={
              <div>
                <p>{t("participants")}:</p>
                {/* <p>
                  <strong>{t("victorMykyta")}</strong>, <br />
                  {t("victorMykytaTitle")}
                  <br />
                  <em>{t("tbc")}</em>;
                </p> */}
                <p>
                  <strong>{t("MaksymKozytskyy")}</strong>, <br />
                  {t("HeadOfLviv")};
                </p>
                <p>
                  <strong>{t("serhiyLysak")}</strong>, <br />
                  {t("serhiyLysakTitle")};
                </p>
                <p>
                  <strong>{t("andriyTabinsky")}</strong>, <br />
                  {t("andriyTabinskyTitle")};
                </p>
                <p>
                  <strong>{t("victorMykyta")}</strong>, <br />
                  {t("victorMykytaTitle")}
                  <br />
                </p>
                <p>
                  {t("moder")} <strong>{t("valentynaMoskalenco")}</strong>,{" "}
                  <br />
                  {t("valentynaMoskalencoTitle")};
                </p>
              </div>
            }
          />
          <Accordion
            id={"Eleven"}
            title={t("memorandum4signing")}
            time={"17:00-17-30"}
            empty
          />
          <Accordion
            id={"Ten"}
            title={t("conclusion")}
            time={"17:30-18:00"}
            empty
          />
          <Accordion id={"nine"} title={t("transferToLviv")} empty />
        </div>
      </div>
    </div>
  );
}
