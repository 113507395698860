// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-to-calendar,
.dropdown-center {
  margin: 1rem 0;
}
.add-to-calendar .calendar-button,
.dropdown-center .calendar-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
}
.add-to-calendar .calendar-button *,
.dropdown-center .calendar-button * {
  width: 1.5rem;
}
.add-to-calendar .dropdown-menu,
.dropdown-center .dropdown-menu {
  background: transparent;
  border: none;
  background-color: #113418;
  min-width: 2rem;
}
.add-to-calendar .dropdown-menu .dropdown-item,
.dropdown-center .dropdown-menu .dropdown-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 2rem;
  color: white;
  text-align: center;
  border-bottom: 2px solid #113418;
}
.add-to-calendar .dropdown-menu .dropdown-item *,
.dropdown-center .dropdown-menu .dropdown-item * {
  width: 2rem;
  padding: 0 0.5rem;
}
.add-to-calendar .dropdown-menu .dropdown-item:hover,
.dropdown-center .dropdown-menu .dropdown-item:hover {
  background: rgba(245, 246, 246, 0.1);
}`, "",{"version":3,"sources":["webpack://./src/pages/Agenda/Accordion/AddToCalendar/style.scss","webpack://./src/styles/_variables.scss"],"names":[],"mappings":"AAEA;;EAEE,cAAA;AADF;AAEE;;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,uBAAA;EACA,YAAA;AACJ;AAAI;;EACE,aAAA;AAGN;AAAE;;EACE,uBAAA;EACA,YAAA;EACA,yBCjBS;EDkBT,eAAA;AAGJ;AAFI;;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;EACA,YAAA;EAEA,kBAAA;EACA,gCAAA;AAIN;AAHM;;EACE,WAAA;EACA,iBAAA;AAMR;AAJM;;EACE,oCAAA;AAOR","sourcesContent":["@import \"../../../../styles/variables\";\n\n.add-to-calendar,\n.dropdown-center {\n  margin: 1rem 0;\n  .calendar-button {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background: transparent;\n    border: none;\n    * {\n      width: 1.5rem;\n    }\n  }\n  .dropdown-menu {\n    background: transparent;\n    border: none;\n    background-color: $main-color;\n    min-width: 2rem;\n    .dropdown-item {\n      display: flex;\n      flex-direction: column;\n      align-items: center;\n      justify-content: center;\n      max-width: 2rem;\n      color: white;\n\n      text-align: center;\n      border-bottom: 2px solid $main-color;\n      * {\n        width: 2rem;\n        padding: 0 0.5rem;\n      }\n      &:hover {\n        background: rgba($light-gray, 0.1);\n      }\n    }\n  }\n}\n","//colors\n$main-color: #113418;\n$dark-gray: #1c1b1a;\n$light-gray: #f5f6f6;\n\n//gradients\n$gray-green-gradient: linear-gradient(90deg, $dark-gray, $main-color);\n$green-gray-gradient: linear-gradient(90deg, #133418, #1c1b1a);\n\n//font-weight:\n\n$bold: 700;\n$semibold: 500;\n$medium: 300;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
