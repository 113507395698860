import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from "./locales/en/translation.json";
import translationUK from "./locales/uk/translation.json";

const resources = {
  en: {
    translation: translationEN,
  },
  uk: {
    translation: translationUK,
  },
};

const getCurrentLanguage = () => {
  const savedLanguage = localStorage.getItem("language");
  if (savedLanguage) {
    return savedLanguage;
  }
  const browserLanguage = navigator.language || navigator.userLanguage;

  const supportedLanguages = ["en", "uk"];

  return supportedLanguages.includes(browserLanguage) ? browserLanguage : "uk";
};

i18n.use(initReactI18next).init({
  resources,
  lng: getCurrentLanguage(),
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
