import React from "react";
import { Welcome, Title, About, Place, Partners } from "../../components";
import "./style.scss";

export default function Main() {
  return (
    <section className="main">
      <Welcome />
      <Title />
      <About />
      <Place />
      <Partners />
    </section>
  );
}
