import "./style.scss";
import { useTranslation } from "react-i18next";

export default function About() {
  const { t } = useTranslation();
  return (
    <div className="about">
      <div className="container">
        <div className="row">
          <div className="info-card col-12">
            <div className="wrapper">
              <h4> {t("aboutForum.goalTitle")}</h4>
              <p>{t("aboutForum.goal")}</p>
            </div>
          </div>
          <div className="info-card col-12">
            <div className="wrapper">
              <h4>{t("aboutForum.participantsTitle")}</h4>
              <p>{t("aboutForum.participants")}</p>
            </div>
          </div>
          <div className="info-card col-12">
            <div className="wrapper">
              <h4>{t("forumProgram.title")}</h4>
              <p>{t("forumProgram.intro")}</p>
              <ul>
                {t("forumProgram.topics", { returnObjects: true }).map(
                  (topic, index) => (
                    <li key={index}>{topic}</li>
                  )
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
