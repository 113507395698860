import "./style.scss";

export default function Checkbox({ name, label, checked, onChange }) {
  return (
    <label className="checkbox-container">
      <input
        type="checkbox"
        name={name}
        checked={checked}
        onChange={onChange}
      />
      <span className="custom-checkbox"></span>
      <span>{label}</span>
    </label>
  );
}
