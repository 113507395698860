import logo from "../../assets/logos/accuPartners/FMEACA.png";

export default function FedMin({ className = "partner-logo fed-min" }) {
  return (
    <a href="https://www.bmwk.de" target="_blank" rel="noopener noreferrer">
      <img
        src={logo}
        alt="Logotype of Federal Ministry for Economic Affairs and Climate Action"
        className={className}
      />
    </a>
  );
}
