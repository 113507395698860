// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/graphicelements/windturbie.webp", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.not-found {
  min-height: 80vh;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  color: white;
  background-image: linear-gradient(90deg, rgba(17, 52, 24, 0.95), rgba(17, 52, 24, 0.8)), url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom right;
}
.not-found .content * {
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/NotFound/style.scss"],"names":[],"mappings":"AAEA;EACE,gBAAA;EACA,aAAA;EACA,6BAAA;EACA,mBAAA;EACA,sBAAA;EACA,YAAA;EACA,gIAAA;EAMA,4BAAA;EACA,sBAAA;EACA,iCAAA;AANF;AAQI;EACE,kBAAA;AANN","sourcesContent":["@import \"../../styles/variables\";\n\n.not-found {\n  min-height: 80vh;\n  display: flex;\n  justify-content: space-evenly;\n  align-items: center;\n  flex-direction: column;\n  color: white;\n  background-image: linear-gradient(\n      90deg,\n      rgba($main-color, 0.95),\n      rgba($main-color, 0.8)\n    ),\n    url(\"../../assets/graphicelements/windturbie.webp\");\n  background-repeat: no-repeat;\n  background-size: cover;\n  background-position: bottom right;\n  .content {\n    * {\n      text-align: center;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
