import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import axios from "axios";
// import RegistrationIsClosed from "./RegistratonIsClosed/RegistrationIsClosed";
import { Button, Checkbox, Radio, Images, Partners } from "../../components";
import FormNavigation from "./FormNavigation/FormNavigation";
import FormIsSent from "./formIsSent/FormIsSent";
import "./style.scss";
import Textarea from "./Textarea/Textarea";

const Registration = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    full_name: "",
    email: "",
    contact_number: "",
    organisation: "",
    position: "",
    accommodation_needed: false,
    accommodation_option: "",
    attending_all_events: "",
    dietary_restrictions: "",
  });
  const [errors, setErrors] = useState({});
  const [currentStep, setCurrentStep] = useState(1);
  const [isDataSent, setIsDataSent] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const validateStep = () => {
    let newErrors = {};

    if (currentStep === 1) {
      if (!formData.full_name) newErrors.full_name = t("errorFullNameRequired");
      if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email))
        newErrors.email = t("errorInvalidEmail");
      if (!formData.contact_number)
        newErrors.contact_number = t("errorContactNumberRequired");
    }

    if (currentStep === 2) {
      if (!formData.organisation)
        newErrors.organisation = t("errorOrganisationRequired");
      if (!formData.position) newErrors.position = t("errorPositionRequired");
    }

    if (currentStep === 3 && formData.accommodation_needed) {
      if (!formData.accommodation_option)
        newErrors.accommodation_option = t("errorAccommodationOptionRequired");
    }

    if (currentStep === 5) {
      if (isDisabled === true)
        newErrors.agreement = t("errorAgreementRequired");
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateStep()) return;
    const backendUrl = `${process.env.REACT_APP_BACKEND_URL}/api/register/`;
    try {
      await axios.post(backendUrl, formData);
      setIsDataSent(true);
    } catch (error) {
      console.error("There was an error!", error);
    }
  };

  const nextStep = () => {
    if (validateStep()) {
      setCurrentStep((prevStep) => prevStep + 1);
    }
  };

  const prevStep = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  return (
    <>
      <div className="registration" id="register">
        <div className="container">
          <Button
            variant={"light"}
            beforeIcon={<Images.BackArrow color="#000000" />}
            title={t("home")}
            onClick={() => {
              navigate("/");
            }}
          />
          <div className="hero">
            <div className="registration-title">
              <h2>{t("registerMainTitle")}</h2>
            </div>
            <div className="registration-form">
              {!isDataSent ? (
                <form onSubmit={handleSubmit}>
                  {currentStep === 1 && (
                    <>
                      <input
                        type="text"
                        className={`form-control ${
                          errors.full_name && "error"
                        }`}
                        id="fullName"
                        name="full_name"
                        placeholder={t("registerPlaceholderFullName")}
                        required
                        value={formData.full_name}
                        onChange={handleChange}
                      />
                      {errors.full_name && (
                        <div className="error-feedback">{errors.full_name}</div>
                      )}
                      <input
                        type="email"
                        className={`form-control ${errors.email && "error"}`}
                        id="email"
                        name="email"
                        placeholder={t("registerPlaceholderEmail")}
                        required
                        value={formData.email}
                        onChange={handleChange}
                      />
                      {errors.email && (
                        <div className="error-feedback">{errors.email}</div>
                      )}
                      <input
                        type="text"
                        className={`form-control ${
                          errors.contact_number && "error"
                        }`}
                        id="contactNumber"
                        name="contact_number"
                        placeholder={t("registerPlaceholderContactNumber")}
                        required
                        value={formData.contact_number}
                        onChange={handleChange}
                      />
                      {errors.contact_number && (
                        <div className="error-feedback">
                          {errors.contact_number}
                        </div>
                      )}
                      <FormNavigation onNext={nextStep} showPrev={false} />
                    </>
                  )}
                  {currentStep === 2 && (
                    <>
                      <input
                        type="text"
                        className={`form-control ${
                          errors.organisation && "error"
                        }`}
                        id="organisation"
                        name="organisation"
                        placeholder={t("registerPlaceholderOrganisation")}
                        required
                        value={formData.organisation}
                        onChange={handleChange}
                      />
                      {errors.organisation && (
                        <div className="error-feedback">
                          {errors.organisation}
                        </div>
                      )}
                      <input
                        type="text"
                        className={`form-control ${errors.position && "error"}`}
                        id="position"
                        name="position"
                        placeholder={t("registerPlaceholderPosition")}
                        required
                        value={formData.position}
                        onChange={handleChange}
                      />
                      {errors.position && (
                        <div className="error-feedback">{errors.position}</div>
                      )}
                      <FormNavigation onPrev={prevStep} onNext={nextStep} />
                    </>
                  )}
                  {currentStep === 3 && (
                    <>
                      <Checkbox
                        name="accommodation_needed"
                        label={t("registerPlaceholderAccommodationNeeded")}
                        checked={formData.accommodation_needed}
                        onChange={handleChange}
                      />
                      {formData.accommodation_needed && (
                        <div className="radio-wrapper">
                          <Radio
                            name="accommodation_option"
                            value="17-18"
                            checked={formData.accommodation_option === "17-18"}
                            onChange={handleChange}
                            label={t("registerPlaceholderAccommodationOption1")}
                          />
                          <Radio
                            name="accommodation_option"
                            value="18-19"
                            checked={formData.accommodation_option === "18-19"}
                            onChange={handleChange}
                            label={t("registerPlaceholderAccommodationOption2")}
                          />
                          <Radio
                            name="accommodation_option"
                            value="17-19"
                            checked={formData.accommodation_option === "17-19"}
                            onChange={handleChange}
                            label={t("registerPlaceholderAccommodationOption3")}
                          />
                        </div>
                      )}
                      {errors.accommodation_option && (
                        <div className="error-feedback">
                          {errors.accommodation_option}
                        </div>
                      )}
                      <FormNavigation onPrev={prevStep} onNext={nextStep} />
                    </>
                  )}
                  {currentStep === 4 && (
                    <>
                      <Textarea
                        className="form-control"
                        id="attendingAllEvents"
                        name="attending_all_events"
                        placeholder={t("registerPlaceholderAttendingAllEvents")}
                        value={formData.attending_all_events}
                        onChange={handleChange}
                      />
                      <Textarea
                        className="form-control"
                        id="dietaryRestrictions"
                        name="dietary_restrictions"
                        placeholder={t(
                          "registerPlaceholderDietaryRestrictions"
                        )}
                        value={formData.dietary_restrictions}
                        onChange={handleChange}
                      />
                      <FormNavigation onPrev={prevStep} onNext={nextStep} />
                    </>
                  )}
                  {currentStep === 5 && (
                    <>
                      <Checkbox
                        name="agreement"
                        onChange={() => setIsDisabled(!isDisabled)}
                        checked={!isDisabled}
                        label={t("registrationConsentText")}
                      />
                      {errors.agreement && (
                        <div className="error-feedback">{errors.agreement}</div>
                      )}
                      <FormNavigation onPrev={prevStep} showNext={false} />
                      <div className="d-grid">
                        <Button
                          type="submit"
                          title={t("registerSubmitButton")}
                          variant="light"
                          className="submit"
                        />
                      </div>
                    </>
                  )}
                </form>
              ) : (
                <FormIsSent />
              )}
            </div>
          </div>
        </div>
      </div>
      <Partners />
    </>
  );
};

export default Registration;
