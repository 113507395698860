import { useTranslation } from "react-i18next";
import "./style.scss";

export default function Title() {
  const { t } = useTranslation();
  return (
    <div className="title">
      <div className="container line-border">
        <h4>{t("welcomeText")}</h4>
      </div>
    </div>
  );
}
