// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/graphicelements/windturbie.webp", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.agenda {
  padding: 1rem 0 2rem 0;
  min-height: 100vh;
  background-image: linear-gradient(90deg, rgba(17, 52, 24, 0.95), rgba(17, 52, 24, 0.8)), url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom right;
  color: white;
}
.agenda h2 {
  margin-top: 2rem;
  text-align: center;
  color: white;
}
.agenda .agenda-card {
  border-radius: 1rem;
  background: rgba(245, 246, 246, 0.1);
  margin-bottom: 1rem;
  padding: 1rem 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/Agenda/style.scss"],"names":[],"mappings":"AAEA;EACE,sBAAA;EACA,iBAAA;EACA,gIAAA;EAMA,4BAAA;EACA,sBAAA;EACA,iCAAA;EACA,YAAA;AANF;AAOE;EACE,gBAAA;EACA,kBAAA;EACA,YAAA;AALJ;AAOE;EACE,mBAAA;EACA,oCAAA;EACA,mBAAA;EACA,eAAA;AALJ","sourcesContent":["@import \"../../styles/variables\";\n\n.agenda {\n  padding: 1rem 0 2rem 0;\n  min-height: 100vh;\n  background-image: linear-gradient(\n      90deg,\n      rgba($main-color, 0.95),\n      rgba($main-color, 0.8)\n    ),\n    url(\"../../assets/graphicelements/windturbie.webp\");\n  background-repeat: no-repeat;\n  background-size: cover;\n  background-position: bottom right;\n  color: white;\n  h2 {\n    margin-top: 2rem;\n    text-align: center;\n    color: white;\n  }\n  .agenda-card {\n    border-radius: 1rem;\n    background: rgba($light-gray, 0.1);\n    margin-bottom: 1rem;\n    padding: 1rem 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
