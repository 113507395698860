// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.partners-page {
  min-height: 80vh;
  padding: 1rem 0 0 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/Partners/style.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,mBAAA;AACF","sourcesContent":[".partners-page {\n  min-height: 80vh;\n  padding: 1rem 0 0 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
