import React from "react";
import { useTranslation } from "react-i18next";
import "./style.scss";
import { Images, Button, Partners } from "../../components";
import { useNavigate } from "react-router-dom";

export default function PartnersPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="partners-page">
      <div className="container">
        {" "}
        <Button
          variant={"transparent-dark"}
          beforeIcon={<Images.BackArrow color={"#000000"} />}
          title={t("home")}
          onClick={() => {
            navigate("/");
          }}
        />
        <Partners />
      </div>
    </div>
  );
}
