import React from "react";
import { useTranslation } from "react-i18next";
import "./style.scss";
// import done from "../../../assets/icons/check-solid.svg";
import { Check } from "../../../components/Images";

const FormIsSent = () => {
  const { t } = useTranslation();
  return (
    <div className="form-is-sent">
      <div className="done">
        <Check />
      </div>
      <div>
        <h3>{t("registerSuccessTitle")}</h3>
        <h5>{t("registerSuccessMessage")}</h5>
      </div>
    </div>
  );
};

export default FormIsSent;
