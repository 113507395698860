// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-is-sent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.form-is-sent .done {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border: 10px solid;
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  margin: 0 0 2rem 0;
}
.form-is-sent .done img {
  width: 4rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/CancelRegistration/formIsSent/style.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;AADF;AAEE;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;EACA,kBAAA;EACA,YAAA;EACA,aAAA;EACA,kBAAA;EACA,kBAAA;AAAJ;AAEI;EACE,WAAA;AAAN","sourcesContent":["@import \"../../../styles/variables\";\n\n.form-is-sent {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  text-align: center;\n  .done {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    padding: 1rem;\n    border: 10px solid;\n    width: 10rem;\n    height: 10rem;\n    border-radius: 50%;\n    margin: 0 0 2rem 0;\n\n    img {\n      width: 4rem;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
