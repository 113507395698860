// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.partners {
  background: #f5f6f6;
  color: black;
  padding: 2rem 0;
}
.partners .wrapper {
  margin-left: 15%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
}
@media (min-width: 768px) {
  .partners .wrapper {
    flex-direction: row;
    align-items: center;
    margin-left: 0;
  }
}
.partners .wrapper .partner-logo {
  width: 15rem;
  margin: 2rem 2rem 2rem 0;
}
.partners .wrapper .min-energy,
.partners .wrapper .fed-min {
  width: 10rem;
}
.partners .wrapper .min-rest {
  width: 11rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Partners/style.scss","webpack://./src/styles/_variables.scss","webpack://./src/styles/_mixins.scss"],"names":[],"mappings":"AAGA;EACE,mBCDW;EDEX,YAAA;EACA,eAAA;AAFF;AAGE;EACE,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,eAAA;AADJ;AECI;EFLF;IAOI,mBAAA;IACA,mBAAA;IACA,cAAA;EACJ;AACF;AACI;EACE,YAAA;EACA,wBAAA;AACN;AACI;;EAEE,YAAA;AACN;AACI;EACE,YAAA;AACN","sourcesContent":["@import \"../../styles/variables\";\n@import \"../../styles/mixins\";\n\n.partners {\n  background: $light-gray;\n  color: black;\n  padding: 2rem 0;\n  .wrapper {\n    margin-left: 15%;\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    flex-wrap: wrap;\n    @include respond-to(md) {\n      flex-direction: row;\n      align-items: center;\n      margin-left: 0;\n    }\n\n    .partner-logo {\n      width: 15rem;\n      margin: 2rem 2rem 2rem 0;\n    }\n    .min-energy,\n    .fed-min {\n      width: 10rem;\n    }\n    .min-rest {\n      width: 11rem;\n    }\n  }\n}\n","//colors\n$main-color: #113418;\n$dark-gray: #1c1b1a;\n$light-gray: #f5f6f6;\n\n//gradients\n$gray-green-gradient: linear-gradient(90deg, $dark-gray, $main-color);\n$green-gray-gradient: linear-gradient(90deg, #133418, #1c1b1a);\n\n//font-weight:\n\n$bold: 700;\n$semibold: 500;\n$medium: 300;\n","$breakpoints: (\n  xs: 0,\n  sm: 576px,\n  md: 768px,\n  lg: 992px,\n  xl: 1200px,\n  xxl: 1400px,\n);\n\n// Mixin for each breakpoint\n@mixin respond-to($breakpoint) {\n  @if map-has-key($breakpoints, $breakpoint) {\n    @media (min-width: map-get($breakpoints, $breakpoint)) {\n      @content;\n    }\n  } @else {\n    @warn \"Invalid breakpoint: #{$breakpoint}.\";\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
