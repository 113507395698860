import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from "./components/footer/Footer";
import {
  Registration,
  Main,
  Agenda,
  NotFound,
  Partners,
  CancelRegistration,
  Auction,
} from "./pages";
import "./App.scss";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/registration" element={<Registration />} />
          <Route path="/cancel-registration" element={<CancelRegistration />} />
          <Route path="/partners" element={<Partners />} />
          {/* <Route path="/auction" element={<Auction />} /> */}
          <Route path="/agenda" element={<Agenda />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
