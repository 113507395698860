import "./style.scss";
import { useTranslation } from "react-i18next";

export default function Location() {
  const { t } = useTranslation("translation", { keyPrefix: "location" });
  return (
    <div className="location">
      <div className="container">
        <h4>{t("heading")}</h4>
        <span>{t("subheading")}</span>
      </div>
    </div>
  );
}
