// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cancel-registration .cancel-registration-title {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (min-width: 768px) {
  .cancel-registration .cancel-registration-title {
    padding: 0 10%;
  }
}
.cancel-registration .cancel-registration-title * {
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/CancelRegistration/style.scss","webpack://./src/styles/_mixins.scss"],"names":[],"mappings":"AAGE;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;AAFJ;ACQI;EDTF;IAKI,cAAA;EAAJ;AACF;AACI;EACE,kBAAA;AACN","sourcesContent":["@import \"../../styles/mixins\";\n\n.cancel-registration {\n  .cancel-registration-title {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    @include respond-to(md) {\n      padding: 0 10%;\n    }\n    * {\n      text-align: center;\n    }\n  }\n}\n","$breakpoints: (\n  xs: 0,\n  sm: 576px,\n  md: 768px,\n  lg: 992px,\n  xl: 1200px,\n  xxl: 1400px,\n);\n\n// Mixin for each breakpoint\n@mixin respond-to($breakpoint) {\n  @if map-has-key($breakpoints, $breakpoint) {\n    @media (min-width: map-get($breakpoints, $breakpoint)) {\n      @content;\n    }\n  } @else {\n    @warn \"Invalid breakpoint: #{$breakpoint}.\";\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
