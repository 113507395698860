import "./style.scss";
import { Button, Images, Partners } from "../../components";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import LanguageSwitcher from "../../components/languageSwitcher/LanguageSwitcher";

export default function Auction() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <section className="auction">
      <LanguageSwitcher />
      <div className="container">
        <Button
          variant={"transparent"}
          beforeIcon={<Images.BackArrow color={"#ffffff"} />}
          title={t("home")}
          onClick={() => {
            navigate("/");
          }}
        />
        <h2>{t("auctionMainTitle")}</h2>
      </div>
      <Partners />
    </section>
  );
}
