import "./style.scss";

export default function Radio({ name, value, checked, onChange, label }) {
  return (
    <label className="radio-container">
      <input
        type="radio"
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
      />
      <span className="custom-radio"></span>
      <span>{label}</span>
    </label>
  );
}
