// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-navigation {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/Registration/FormNavigation/style.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,cAAA;AACF","sourcesContent":[".form-navigation {\n  display: flex;\n  justify-content: space-between;\n  margin: 1rem 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
