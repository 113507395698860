import { useTranslation } from "react-i18next";
import "./style.scss";

export default function Place() {
  const { t } = useTranslation();
  return (
    <section className="place">
      <div className="background">
        <div className="container">
          <div>
            {t("slogan", { returnObjects: true }).map((slogan, index) => (
              <h4 className="slogan" key={index}>
                {slogan}
              </h4>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
